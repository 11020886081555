
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

h1 {
  margin-bottom: 40px;
}

body {
  background: #f3f4f4;
}

.page {
  border-top: 4px solid #16A085;
  position: relative;
  z-index:2;
  > .container {
    background: #f5f8fa;
    padding-top: 80px;
    padding-bottom: 80px;
    img {
      width: 100%;
      margin: 40px 0;
      border: 1px solid #16A085;
      border-radius: 4px;
    }
  }
}

.navigation {
  ul {
    padding: 0;
    list-style: none;
    a, a:visited {
      color: #16A085;
    }
  }
  & > ul {
    > li > span {
      font-weight: bold;
      display: block;
      padding: 10px;
      border-bottom: 1px solid $laravel-border-color;
    }
    > li {
      > ul {
        padding: 10px;
        border-bottom: 1px solid $laravel-border-color;
        &:empty {
          display: none;
        }
      }
      &:last-child > ul {
        border-bottom: none;
      }
    }
    li.active {
      a, a:visited {
        font-weight: bold;
        color: $brand-danger;
      }
    }
  }
}

blockquote {
  border-radius: 4px;
  background: #f4645f;
  margin: 30px 0;
  padding: 20px 15px;
  bordeR: none;
  p {
    font-size: 14.5px;
    line-height: 1.7;
    color: white;
  }
}

h1 {
  color: #16A085;
}

h2,  h3, h4, h5, h6 {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #16A085;
  font-size: 22px;
}

h4, h5, h6 {
  font-size: 16px;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  margin: 40px 0;
}

.sphere {
  display: none;
  transform-origin: bottom right;
  transform: scale(2);
  opacity: 0.2;
  width: 200px;
  height: 200px;
  background: #f5f8fa;
  border-radius: 100%;
  position: fixed;
  bottom: -150px;
  right: -150px;
  z-index: 1;
  margin: -100px 0 0 -100px;
  border: 2px solid #16A085;
  overflow: hidden;
}
.sphere:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  content: " ";
  display: inline-block;
  width: 200px;
  height: 200px;
  background: #16A085;
  border-radius: 100%;
  margin: -80px 0 0 -80px;
}
.sphere:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  content: " ";
  display: block;
  width: 200px;
  height: 200px;
  background: #1ABC9C;
  border-radius: 100%;
  margin: -30px 0 0 -30px;
}

h1 {
  margin-top: 0;
}

.search-box {
  position: relative;
  #results {
    position: absolute;
    top: 100%;  left: 0;
    width: 300%;
    background: white;
    z-index: 9;
    border-radius: 4px;
    border: 1px solid $panel-inner-border;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding-bottom: 70px;
    background-image: url("/images/Algolia_logo_bg-white.svg");
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: 20px calc(100% - 17px);
    p {
      margin: 0;
      padding: 4px 10px;
      em {
        color: $brand-danger;
        font-weight: bold;
      }
      &.title {
        display: inline-block;
        background: #f3f4f4;
        border-radius: 4px;
      }
    }
    li {
      padding: 10px;
      border-bottom: 1px solid $panel-inner-border;
    }
  }
}

.panel {
  padding: 20px;
  > *:last-child {
    margin-bottom: 0;
  }
}
